<template>
	<div>
		<H5header :type="2"></H5header>
		<img :src="image" alt="" class="backimg">
		<div>
			<div class="xqxx">
				<div class="title">{{ date.matchName }}</div>
			</div>
			<p class="head-name">ꔷ 赛事项目：{{ date.matchSport_dictText }}</p>
			<!-- <p class="head-name">ꔷ 赛事性质：{{date.matchProperty_dictText}}</p> -->
			<p class="head-name">ꔷ 赛事种类：{{ date.matchCategory_dictText }}</p>
			<p class="head-name">ꔷ 赛事级别：{{ date.matchLevel_dictText }}</p>
			<div class="head-name" style="display: flex">
				<div class="name">ꔷ 赛事时间：</div>
				<div>
					<div v-for="(item, index) in date.matchDate" :key="index">
						<div v-if="item.startDate == item.endDate">{{ item.startDate }} </div>
						<div v-else>{{ item.startDate }} 至 {{ item.endDate }}</div>
					</div>
				</div>
			</div>
			<p class="head-name">ꔷ 赛事地点：{{ date.matchAddressStadium }}</p>
			<p class="head-name">ꔷ 赛事规模：{{ date.matchScale }}</p>
			<p class="head-name">ꔷ 主办单位：{{ date.hostUnit }}</p>
			<p class="head-name">ꔷ 承办单位：{{ date.matchOrganizer }}</p>
			<p class="head-name">ꔷ 协办单位：{{ date.matchCoOrganizer }}</p>
			<p class="head-name">ꔷ 赛事状态：{{ date.matchRunStatus }}</p>
			<p class="head-name">（以上赛事信息由办赛单位提供，不作为赛事审批依据）</p>
		</div>
		<div class="xian"></div>

		<div class="event">
			<div :class="triggerindex == 1 ? 'eventbut eventbut2' : 'eventbut'" @click="getevent(1)">
				赛事简介
			</div>
			<div :class="triggerindex == 8 ? 'eventbut eventbut2' : 'eventbut'" @click="getevent(8)">
				竞赛规程
			</div>
			<div v-for="(item, index) in threeLevel" :key="index"
				:class="triggerindex == index + 2 ? 'eventbut eventbut2' : 'eventbut'"
				@click="getevent(index + 2, item.id, 1, index, item.name, item)">
				{{ item.name }}
			</div>
			<div :class="triggerindex == 7 ? 'eventbut eventbut2' : 'eventbut'" @click="getevent(7)">
				赛事成绩
			</div>
		</div>
		<div class="xian"></div>
		<div class="juti">
			<div v-html="date.matchBriefIntroduction" v-if="triggerindex == 1 && date.matchBriefIntroduction"></div>
			<div v-if="triggerindex == 1 && !date.matchBriefIntroduction">暂无信息，请稍后访问</div>

			<div v-html="date.matchRules" v-if="triggerindex == 8 && date.matchRules"></div>
			<div v-if="triggerindex == 8 && !date.matchRules">暂无信息，请稍后访问</div>

			<div v-if="matchdata && triggerindex != 7 && triggerindex != 1 && triggerindex != 8">
				<div v-if="matchdata.length > 0">
					<div class="articlelist" v-for="(item, index) in matchdata" :key="index"
						v-if="template == 'picture_column' || template == 'picture_list' || template == 'picture'"
						@click="jump2(item)">
						<div class="imgbox">
							<div class="title-img">{{ item.title }}</div>
							<img :src="item.imgUrl" alt="" v-if="item.imgUrl">
							<img src="../../assets/image/placeholder.png" alt="" v-else>
						</div>
						<div class="date">{{ item.publishTime | format }}</div>
						<div class="xian"></div>
					</div>
					<div v-for="(item, index) in matchdata" :key="index" v-if="template == 'date_list'"
						@click="jump2(item)">
						<div style="height: 72PX;padding: 20PX;">
							<div class="news-date">
								<b>{{ item.publishTime.split(" ")[0].split("-")[2] }}</b>
								<p>{{ item.publishTime.split(" ")[0].split("-")[0] }}-{{ item.publishTime.split("
									")[0].split(" - ")[1]}}
								</p>
							</div>
							<h2>
								{{ item.title }}
							</h2>
						</div>
						<div class="xian" style="margin: 30PX 0 0 0; "></div>
					</div>
					<div class="articlelist" v-for="(item, index) in matchdata" :key="index"
						v-if="template == 'common_list'" @click="jump2(item)">
						<div class="title">{{ item.title }}</div>
						<div class="date">{{ item.publishTime | format }}</div>
						<div class="xian"></div>
					</div>

					<div class="articlelist" v-for="(item, index) in matchdata" :key="index" v-if="template == ''"
						@click="jump2(item)">
						<div class="title">{{ item.title }}</div>
						<div class="date">{{ item.publishTime | format }}</div>
						<div class="xian"></div>
					</div>

					<div class="articlelist" v-for="(item, index) in matchdata" :key="index"
						v-if="template != 'picture_column' && template != 'common_list' && template != 'picture_list' && template != 'picture' && template != 'date_list'""
						@click=" jump2(item)">
						<div class="title">{{ item.title }}</div>
						<div class="date">{{ item.publishTime | format }}</div>
						<div class="xian"></div>
					</div>
				</div>
				<div class="specific" v-else>暂无信息，请稍后访问</div>
			</div>

			<div v-if="triggerindex == 7">
				<div v-if="distinguish">
					<div class="nian">
						<div style="display: flex;align-items: center;">
							<img src="../../assets/image/runproject.png" alt="">
							<p>选择组别</p>
						</div>
						<div class="select">
							<!-- <el-select v-model="groupName" placeholder="请选择" clearable @change="getxm">
								<el-option v-for="item in options2" :key="item.groupName" :label="item.groupName"
									:value="item.groupName">
								</el-option>
							</el-select> -->
							<a-select option-label-prop="label"  placeholder="请选择" v-model="groupName" allowClear  @change="getxm">
								<a-select-option v-for="item in options2" :key="item.groupName" :value="item.groupName" :label="item.groupName">
									<a-icon type="check" v-if="item===groupName"/> 
									{{item.groupName}}
								</a-select-option>
							</a-select>
						</div>
					</div>
					<div class="xian2"></div>
					<div class="nian">
						<div style="display: flex;align-items: center;">
							<img src="../../assets/image/project2.png" alt="">
							<p>选择项目</p>
						</div>
						<div class="select">
							<!-- <el-select v-model="projectName" placeholder="请选择" filterable clearable @change="getxm1">
								<el-option v-for="item in options1" :key="item.projectName" :label="item.projectName"
									:value="item.projectName">
								</el-option>
							</el-select> -->
							<a-select option-label-prop="label"  placeholder="请选择" v-model="projectName" allowClear  @change="getxm1">
								<a-select-option v-for="item in options1" :key="item.projectName" :value="item.projectName" :label="item.projectName">
									<a-icon type="check" v-if="item===projectName"/> 
									{{item.projectName}}
								</a-select-option>
							</a-select>
						</div>
					</div>
					<div class="xian2"></div>
					<div class="nian">
						<div style="display: flex;align-items: center;">
							<img src="../../assets/image/project.png" alt="">
							<p>选择赛次</p>
						</div>
						<div class="select">
							<!-- <el-select v-model="games" placeholder="请选择" clearable>
								<el-option v-for="item in options3" :key="item.roundName" :label="item.roundName"
									:value="item.roundName">
								</el-option>
							</el-select> -->
							<a-select option-label-prop="label"  placeholder="请选择" v-model="games" allowClear>
								<a-select-option v-for="item in options3" :key="item.roundName" :value="item.roundName" :label="item.roundName">
									<a-icon type="check" v-if="item===games"/> 
									{{item.roundName}}
								</a-select-option>
							</a-select>
						</div>
					</div>
					<div class="xian2"></div>
					<div class="but" @click="searchfor">
						搜索
					</div>

					<div style="overflow-x: scroll;">
						<div class="table">
							<el-table :data="tableData" v-loading="loading" border style="width: 100%;"
								:header-cell-style="{ background: '#0A6DF3' }">
								<el-table-column type="index" label="序号" align="center" width="42">
								</el-table-column>
								<el-table-column prop="groupName" label="组别" align="center">
								</el-table-column>
								<el-table-column prop="projectName" label="项目" align="center">
								</el-table-column>
								<el-table-column prop="games" label="轮次" align="center">
								</el-table-column>
								<el-table-column prop="name" label="操作" align="center" width="50">
									<template slot-scope="scope">
										<el-link type="primary" :underline="false"
											@click="getdetails(scope.row)">查看</el-link>
									</template>
								</el-table-column>
							</el-table>
							<div v-if="tableData.length > 0" style="margin-top: 10PX;">
								赛事成绩由办赛单位提供并保证其真实性，如有问题请与办赛单位联系。</div>
							<div v-if="tableData.length > 0" style="margin-top: 10PX;">
								如有技术问题，请拨打以下电话与网站技术联系。</div>
							<div v-if="tableData.length > 0" style="margin-top: 10PX;">
								联系电话：010-63827176，咨询时间：工作日（周一至周五）9:00 - 17:00</div>
							<div v-else style="margin-top: 10PX;">赛事成绩未录入，请稍后访问</div>
						</div>
					</div>
				</div>
				<div v-else class="table" style="overflow-x: auto;">
					<div class="tab_title">{{ this.row.matchName }}</div>
					<div class="tab_title tab_title1">组别: {{ this.row.groupName }}</div>
					<div class="tab_title tab_title1">项目: {{ this.row.projectName }}</div>
					<div class="tab_title tab_title1">轮次: {{ this.row.games }}</div>
					<div v-for="(item, index) in tableData2" class="xbuju-box">
						<div class="xbuju">
							<div class="ranking">
								<el-link type="primary" :underline="false"
									style="font-size: 17PX;overflow-wrap: break-word;justify-content: left;margin-right: 10PX;"
									@click="jump(item)">{{item.convertName }}
								</el-link>

								<div v-for="(items, indexs) in item.userNamess" :key="indexs" style="display: flex;">
									<el-link type="primary" :underline="false" @click="jump(items)"
										style="margin-right: 5PX;font-size: 17PX;">{{ items }}</el-link>
									<div v-if="indexs != item.userNamess.length - 1">、</div>
								</div>
							</div>
						</div>
						<div class="xbujugr">成绩：{{ item.grade }}</div>
						<div class="xbujugr">代表队：{{ item.teamName }}</div>
						<div class="xbujugr">备注：{{ item.remarks }}</div>
						<div class="xian"></div>
					</div>
					<!-- <el-table :data="tableData2" border style="width: 100%;"
						:header-cell-style="{ background: '#0A6DF3' }">
						<el-table-column prop="ranking" label="名次" align="center" width="42">
						</el-table-column>
						<el-table-column prop="userNames" label="姓名" align="center">
							<template slot-scope="scope">
								
							</template>
						</el-table-column>
						<el-table-column prop="teamName" label="代表队" align="center">
						</el-table-column>
						<el-table-column prop="grade" label="成绩" align="center">
						</el-table-column>
						<el-table-column prop="remarks" label="备注" align="center" width="55">
						</el-table-column>
					</el-table> -->
					<!-- <div class="paging">
						<pagination :total="total3" @currentchange="currentchange" :type="2" :go="10" :pageSize="10">
						</pagination>
					</div> -->
					<div class="but2" @click="returnfor">
						<div class="text2">返回</div>
					</div>
					<div style="height: 20px;width: 100%;"></div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import Meta from 'vue-meta'
Vue.use(Meta)
import H5header from "./components/header2.vue"
export default {
	name: '',
	components: {
		H5header
	},
	data() {
		return {
			id: '',
			date: '',
			triggerindex: 1,
			title: '',
			title1: '',
			title2: '',
			title3: '',
			title4: '',
			title5: '',
			title6: '',
			title7: '0',
			title8: '',
			title9: '',
			title10: '',
			imgs: '',
			total: 0,
			columnId: '',
			columnPid: '',
			threeLevel: [],
			tableData: [],
			options: [],
			matchdata: {},
			options1: [],
			options2: [],
			options3: [],
			name: '',
			distinguish: true,
			difference: true,
			info: {
				matchId: '',
				groupName: '',
				projectName: '',
				games: '',
			},
			sports: '',
			matchName: '',
			tableData2: [],
			tableData3: [],
			image: '',
			domain: 'https://t-jsglzx.bgosp.com',
			domain2: 'https://admin.bjcac.org.cn',
			template: '',
			pageNo: 1,
			pageSize: 10,
			go: 10,
			row: {},
			total3: 0,

			groupName: undefined,
			projectName: undefined,
			games: undefined,
			loading:false,
			navid:''
		}
	},
	filters: {
		format(x) {
			if (x != null && x != '') {
				return x.split(" ")[0]
			}
		}
	},
	created() {
		// this.sportEventList()
		this.imgs = this.$route.query.imgs
		this.id = this.$route.query.id
		this.columnId = this.$route.query.columnId
		this.columnPid = this.$route.query.columnPid
		this.getMatchScoreThreeLinkQuery()
		this.eventIntroduction()
		this.findAllColumn()
		if (this.$route.query.type == 'achievement') {
			this.gradeGrouping()
			this.triggerindex = 7
		}
	},
	methods: {
		getMatchScoreThreeLinkQuery() {
			this.$api.getMatchScoreThreeLinkQuery({
				matchId: this.$route.query.id
			}).then(res => {
				this.options2 = res.data.data.result
			})
		},
		// sportEventList() {
		// 	this.$api.sportEventList({dictValue:'其他'}).then(res => {
		// 		this.options1 = res.data.data.result
		// 	})
		// },
		eventIntroduction() {
			this.$api.eventIntroduction(this.id).then(res => {
				res.data.data.result.matchAddressStadium = res.data.data.result.addresses.map(item => {
					return item.matchAddressStadium
				}).join('、')

				if (res.data.data.result.matchImgs && res.data.data.result.matchImgs.length > 0) {
					this.$api.getFile({
						objectName: res.data.data.result.matchImgs[0].filePath
					}).then(russ => {
						this.$convert(russ.data.data.result.url).then((fileUrl) => {
							this.image = fileUrl
							this.$forceUpdate()
						})
					})
				}

				if (res.data.data.result.matchBriefIntroduction) {
					res.data.data.result.matchBriefIntroduction = this.updatedHtml(res.data.data.result
						.matchBriefIntroduction)
					let imgReg = RegExp(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi); //定义正则，筛选出img元素
					let matchres = res.data.data.result.matchBriefIntroduction.match(imgReg);
					if (matchres) {
						matchres.forEach((item) => {
							if (item.indexOf('staticMinioFileCheckSecurity') == -1) {
								return // 说明是历史数据需要返回原来的 不需要改变
							}
							// 从富文本中拿到img标签 并去掉 "/>"
							let _tempStr = item.slice(0, item.length - 2);
							let _index = _tempStr.indexOf('?');
							// 拿到文件的所有旧文件标签 [ img, img, img]
							const srcRegExp = /(\/|http)[^>]+\.(jpg|jpeg|png|gif)/g;
							const _InitialImgSrc = _tempStr.match(srcRegExp);
							// 拿到 minFileName 获取新的src
							if (_InitialImgSrc && _InitialImgSrc.length > 0) {
								// 拿到文件的minFileName  事例： jeditor/90490405b7384040bfdd1d6e7bc14a79_1688977979650OSS.png
								let _fileNameIndex = _InitialImgSrc[0].indexOf('jeditor/');
								let _minFileName = _InitialImgSrc[0].substring(_fileNameIndex,
									_InitialImgSrc[0].length);
								// 拿到文件的minFileName + 后续属性 事例： jeditor/31580d55a8cc489c8d8aa92f59b310c0_1688977994678OSS.png" alt="" width="48" height="48"
								let longHtml = _tempStr.substring(_tempStr.indexOf(_minFileName),
									_tempStr
										.length);
								// 拿到属性 事例： alt="" width="100" height="100"
								let propName = longHtml.substring(longHtml.indexOf(' '), longHtml
									.length);
								// 获取新的验签
								const params = this.$getSign(_minFileName);
								// 拼接img标签
								let _str = _tempStr.substring(0, _index) + '?' + params + '"' +
									propName +
									'/>';
								// 替换img标签
								res.data.data.result.matchBriefIntroduction = res.data.data.result
									.matchBriefIntroduction.replace(item, _str);
							}
						});
					}
				}
				if (res.data.data.result.matchRules) {
					res.data.data.result.matchRules = this.updatedHtml(res.data.data.result.matchRules)
					let imgReg = RegExp(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi); //定义正则，筛选出img元素
					let matchres = res.data.data.result.matchRules.match(imgReg);
					if (matchres) {
						matchres.forEach((item) => {
							if (item.indexOf('staticMinioFileCheckSecurity') == -1) {
								return // 说明是历史数据需要返回原来的 不需要改变
							}
							// 从富文本中拿到img标签 并去掉 "/>"
							let _tempStr = item.slice(0, item.length - 2);
							let _index = _tempStr.indexOf('?');
							// 拿到文件的所有旧文件标签 [ img, img, img]
							const srcRegExp = /(\/|http)[^>]+\.(jpg|jpeg|png|gif)/g;
							const _InitialImgSrc = _tempStr.match(srcRegExp);
							// 拿到 minFileName 获取新的src
							if (_InitialImgSrc && _InitialImgSrc.length > 0) {
								// 拿到文件的minFileName  事例： jeditor/90490405b7384040bfdd1d6e7bc14a79_1688977979650OSS.png
								let _fileNameIndex = _InitialImgSrc[0].indexOf('jeditor/');
								let _minFileName = _InitialImgSrc[0].substring(_fileNameIndex,
									_InitialImgSrc[0].length);
								// 拿到文件的minFileName + 后续属性 事例： jeditor/31580d55a8cc489c8d8aa92f59b310c0_1688977994678OSS.png" alt="" width="48" height="48"
								let longHtml = _tempStr.substring(_tempStr.indexOf(_minFileName),
									_tempStr
										.length);
								// 拿到属性 事例： alt="" width="100" height="100"
								let propName = longHtml.substring(longHtml.indexOf(' '), longHtml
									.length);
								// 获取新的验签
								const params = this.$getSign(_minFileName);
								// 拼接img标签
								let _str = _tempStr.substring(0, _index) + '?' + params + '"' +
									propName +
									'/>';
								// 替换img标签
								res.data.data.result.matchRules = res.data.data.result
									.matchRules.replace(item, _str);
							}
						});
					}
				}

				let exp = /^[+-]?\d*(\.\d*)?(e[+-]?\d+)?$/
				if (exp.test(res.data.data.result.matchScale)) {
					res.data.data.result.matchScale = res.data.data.result.matchScale + '人'
				}
				res.data.data.result.matchDate = JSON.parse(res.data.data.result.matchDate)

				this.date = res.data.data.result
				document.title = res.data.data.result.matchName + '-北京市体育竞赛管理和国际交流中心'

				let img = 'https://admin.bjcac.org.cn/jeecgboot/jeecg-system/sys/common/staticMinioFile?minFileName=' + res.data.data.result.matchImgs[0].filePath
				this.$getwxsdk(res.data.data.result.matchName + '-北京市体育竞赛管理和国际交流中心', img)
				this.$meta().refresh()
			})
		},
		updatedHtml(url) {
			const imgSrcRegex = /<img[^>]+src="([^"]+)"/g;
			let updatedHtml = url;
			let match;
			while ((match = imgSrcRegex.exec(url)) !== null) {
				const imgSrc = match[1];
				if (!/^https?:\/\//i.test(imgSrc)) {
					if (process.env.VUE_APP_BASE_API == 'https://www.bjcac.org.cn') {
						const updatedSrc = `${this.domain2.replace(/\/$/, '')}/${this.removeDotDotSlash(imgSrc)}`;
						updatedHtml = updatedHtml.replace(imgSrc, updatedSrc);
					} else {
						const updatedSrc = `${this.domain.replace(/\/$/, '')}/${this.removeDotDotSlash(imgSrc)}`;
						updatedHtml = updatedHtml.replace(imgSrc, updatedSrc);
					}
				}
			}
			return updatedHtml;
		},
		removeDotDotSlash(url) {
			let updatedUrl = url;
			while (updatedUrl.indexOf('../') !== -1) {
				updatedUrl = updatedUrl.replace('../', '');
			}
			return updatedUrl;
		},
		findAllColumn() {
			this.$api.findAllColumn({
				id: this.columnId,
				pid: this.columnPid
			}).then(res => {
				for (let i in res.data.data.list[0].children) {
					if (res.data.data.list[0].children[i].name == '登记/查询') {
						this.threeLevel = res.data.data.list[0].children[i].children
						// if (this.$route.query.type == 'home') {
						// 	for (let x in this.threeLevel) {
						// 		if (this.threeLevel[x].name == '精彩图集' || this.threeLevel[x].name == '精彩瞬间') {
						// 			this.triggerindex = x + 2
						// 			this.gettrigger(x + 2, this.threeLevel[x].id, 1, x, this.threeLevel[x].name)
						// 		}
						// 	}
						// }
					}
				}
			})
		},
		getevent(e, id, type, index, name, item) {
			if (item) {
				this.template = item.template
			}
			this.name = name
			this.triggerindex = e
			this.navid = id
			if (e == 7) {
				this.gradeGrouping()
			} else {
				if (type == 1) {
					let data = {
						id: id,
						matchId: this.id,
						pageNo: 0,
						pageSize: 10
					}
					this.$api.matchArticle(data).then(res => {
						this.matchdata = res.data.data.records
					})
				}
			}
		},
		getxm(e) {
			if (e.length > 15) {
				this.groupName = e.slice(0, 14) + '...'
			} else {
				this.groupName = e
			}
			this.info.groupName = e

			this.options1 = []
			this.info.projectName = ''
			this.info.games = ''
			this.projectName = undefined
			this.games = undefined
			for (let i in this.options2) {
				if (this.options2[i].groupName == e) {
					this.options1 = this.options2[i].projectNameVoList
				}
			}
		},
		getxm1(e) {
			if (e.length > 15) {
				this.projectName = e.slice(0, 14) + '...'
			} else {
				this.projectName = e
			}
			this.info.projectName = e

			this.options3 = []
			this.info.games = ''
			this.games = undefined
			for (let i in this.options1) {
				if (this.options1[i].projectName == e) {
					this.options3 = this.options1[i].scoreRoundVoList
				}
			}
		},
		getxm2(e) {
			if (e.length > 15) {
				this.games = e.slice(0, 14) + '...'
			} else {
				this.games = e
			}
			this.info.games = e
		},
		gradeGrouping() {
			this.info.matchId = this.id
			this.loading = true
			this.$api.gradeGrouping(this.info).then(res => {
				this.tableData = res.data.data.result
				this.loading = false
			}).catch(error => {
				this.loading = false
			})
		},
		currentchange(e) {
			this.pageNo = e
			this.eventResultsDetails()
		},
		getdetails(row) {
			this.row = row
			this.distinguish = false
			this.eventResultsDetails()
			window.scrollTo(0, 0)
		},
		eventResultsDetails() {
			let data = {
				matchId: this.id,
				groupName: this.row.groupName,
				projectName: this.row.projectName,
				games: this.row.games,
				projectName: this.row.projectName,
				matchName: this.row.matchName,
				pageNo: this.pageNo,
				pageSize: this.pageSize
			}

			this.$api.eventResultsDetails(data).then(res => {
				res.data.data.result.data.forEach(item => {
					if (item.ranking != '--') {
						item.convertName = '第' + item.ranking + '名'
						item.userNamess = this.$decrypt(item.userNames).split(/[,，、]/)
					} else {
						item.convertName = '未排名'
						item.userNamess = this.$decrypt(item.userNames).split(/[,，、]/)
					}
				})
				this.tableData2 = res.data.data.result.data
				this.total3 = res.data.data.result.count
			})
		},
		searchfor() {
			this.info.matchId = this.id
			this.gradeGrouping()
		},
		returnfor() {
			this.distinguish = true
			this.info = {
				matchId: '',
				groupName: '',
				projectName: '',
				games: '',
			}
			this.groupName = undefined
			this.projectName = undefined
			this.games = undefined
			this.gradeGrouping()
			window.scrollTo(0, 0)
		},
		jump(row) {
			this.$router.push({
				path: '/h5/H5notice',
				query: {
					names: row,
					name: '成绩查询',
					type: 1,
					id: 737,
					pid: 720,
					refresh: new Date().getTime()
				}
			});
		},
		jump2(item) {
			if(this.navid == 728){
				if (this.$store.state.token == '') {
					this.$confirm('请登录后查看更多信息', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$store.commit("EXIT_TOKEN")
						this.$router.push({
							path: '/h5/login',
							query: {
								person: true,
								grp: false,
								refresh: new Date().getTime()
							}
						})
					}).catch(() => { });
					return
				}
			}
			this.$router.push({
				path: '/h5/H5articledetails',
				query: {
					id: item.id,
					refresh: new Date().getTime(),
				}
			})
		},
	}
}
</script>

<style scoped lang="scss">
.backimg {
	width: 100%;
	height: 220PX;
}

.xqxx {
	.title {
		font-size: 15PX;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		padding: 16PX 20PX;
		border-bottom: 1PX solid rgba(197, 197, 197, 0.2);
	}
}

.head-name {
	margin-top: 8PX;
	margin-left: 20PX;
	font-size: 13PX;
	font-weight: bold;
}

.xian {
	margin-top: 11PX;
	width: 100%;
	height: 4PX;
	background: #D8DDE6;
	opacity: 0.28;
}

.event {
	margin: 11PX 20PX 0;
	display: flex;
	flex-wrap: wrap;

	.eventbut {
		padding: 4PX 10PX;
		background: #EFF6FF;
		border-radius: 5PX;
		margin-right: 21PX;
		margin-bottom: 11PX;
	}

	.eventbut2 {
		border: 1PX solid rgba(30, 113, 239, 0.5);
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #1E71EF;
	}
}

.juti {
	margin: 11PX 20PX;
}

.nian {
	display: flex;
	align-items: center;
	justify-content: space-between;

	img {
		width: 17PX;
		height: 17PX;
		flex-shrink: 0;
	}

	p {
		font-size: 14PX;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		margin-left: 12PX;
		flex-shrink: 0;
	}

	.select ::v-deep .el-select .el-input .el-input__inner {
		border-color: transparent !important;
		background-color: transparent !important;
		box-shadow: none !important;
		color: #000;
	}

	.select ::v-deep .el-select .el-input,
	.select ::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.select ::v-deep .el-select .el-input .el-select__caret.is-reverse {
		transform: rotateZ();
	}

	.select ::v-deep .el-select .el-input .el-select__caret {
		color: #C0C4CC;
		font-size: 14PX;
		transition: transform .3s;
		transform: rotateZ(89deg);
		cursor: pointer;
		left: 15PX;
		position: relative;
	}

	.select ::v-deep input::-webkit-input-placeholder {
		color: #000;
	}

	.select ::v-deep .el-select .el-input .el-select__caret {
		color: #000;
	}

	::v-deep .el-input .el-input__inner {
		border: none !important;
		padding: 0 12PX;
	}

	::v-deep input::-webkit-input-placeholder {
		color: #000;
	}
}

.xian2 {

	height: 1PX;
	background: rgba(194, 194, 194, 0.2);
}

.but {
	margin: 20PX 0;
	width: 100%;
	height: 37PX;
	background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
	border-radius: 50PX;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16PX;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #FFFFFF;
}

.table {
	margin-bottom: 40PX;
}

.table ::v-deep .el-table thead {
	color: #fff;
	font-size: 16PX;
	font-weight: 500;
	font-size: 11PX;
}

.table ::v-deep .el-table__header-wrapper {
	border-top-left-radius: 10PX;
	border-top-right-radius: 10PX;
}

.table ::v-deep .el-table {
	border-top-left-radius: 10PX;
	border-top-right-radius: 10PX;
}

.table ::v-deep .el-table th.el-table__cell.is-leaf {
	border-bottom: 1PX solid #000;
	border-right: 1PX solid #000;
}

.table ::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
	border-color: black;
}

.table ::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
	background-color: black;
}

.tab_title_box {
	display: flex;
	justify-content: center;
	margin-bottom: 10PX;

	.tab_title_box1 {
		margin-left: 10PX;
	}

	.tab_title_box2 {
		margin-left: 10PX;
	}
}



.but2 {
	margin: 20PX 25PX 0;
	width: calc(100% - 50PX);
	height: 37PX;
	background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
	border-radius: 50PX;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16PX;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #FFFFFF;
}

.articlelist {

	.title {
		height: 36PX;
		font-size: 14PX;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		margin: 17PX 22PX 0 22PX;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.date {
		font-size: 11PX;
		font-family: Source Han Sans CN;
		font-weight: 400;
		margin-top: 5PX;
		margin-right: 23PX;
		text-align: right;
		margin-bottom: 12PX;
	}


	.imgbox {
		margin: 17PX 22PX 0 22PX;
		display: flex;
		justify-content: space-between;

		img {
			width: 114PX;
			height: 70PX;
			border-radius: 10PX;
			flex-shrink: 0;
		}

		.title-img {
			height: 73PX;
			font-size: 14PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			line-clamp: 4;
			-webkit-box-orient: vertical;
			margin-right: 10PX;
		}
	}
}

.news-date {
	width: 72PX;
	height: 72PX;
	border: 1PX solid #bfd4e9;
	float: left;
	margin-right: 20PX;
	font-family: arial;
	color: #6198e2;
	background: #fff;
	text-align: center;
	transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.news-date b {
	height: 43PX;
	line-height: 42PX;
	font-size: 26PX;
}

.news-date p {
	font-size: 14PX;
	margin: 0 5PX;
	height: 26PX;
	line-height: 26PX;
	color: #6198e2;
	background: #fff;
	border-top: 1PX solid #dfe7f3;
	transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

h2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	line-clamp: 4;
	-webkit-box-orient: vertical;
}

.xian {
	width: 100%;
	height: 4PX;
	background: #F4F8FB;
}

.tab_title {
	font-size: 18PX;
	margin-bottom: 5PX;
	margin-left: 20PX;
	font-weight: 600;
}

.tab_title1 {
	font-size: 15PX;
	font-weight: 400;
}

.xbuju-box {
	font-family: Microsoft YaHei;

	.xbuju {
		display: flex;
		margin-top: 10PX;

		.ranking {
			word-break: break-all;
			overflow-wrap: break-word;
			line-height: 30PX;
			font-size: 17PX;
			display: flex;
			flex-wrap: wrap;

			::v-deep .el-link--inner {
				word-break: break-all;
				overflow-wrap: break-word;
			}
		}
	}

	.xbujugr {
		margin-top: 10PX;
		color: #B5B5B5;
		font-size: 15PX;
	}
}
</style>